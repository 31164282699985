import { useItineraryStore } from '~/stores/api/ItineraryStore';
import { CanNotInsertTransportRequestError } from '~/exceptions/itinerary/day/CanNotInsertTransportRequest';
import { ChooseNewConfigurationError } from '~/exceptions/itinerary/day/ChooseNewConfiguration';
import type ItineraryPayload from '~/api/payload/ItineraryPayload';
import { ClientError } from '~/api/error/ClientError';
import { CanInsertTransportRequestEnum } from '~/api/enum/CanInsertTransportRequestEnum';

export const useItineraryDayStore = defineStore('itinerary-day', {
  state: (): {
    currentGroupedTransportRequestId: string | null;
    currentMarkerId: number | null;
    currentItineraryId: number | null;
    softRefresh: boolean;
    itineraryPointIdToDelete: number | null;
    itineraryIdToEdit: number | null;
    itineraryPointLabelToDelete: string | null;
  } => ({
    currentMarkerId: null,
    currentGroupedTransportRequestId: null,
    currentItineraryId: null,
    softRefresh: false,
    itineraryPointIdToDelete: null,
    itineraryPointLabelToDelete: null,
    itineraryIdToEdit: null
  }),
  actions: {
    setCurrentMarker(idMarker: number | null) {
      this.currentMarkerId = idMarker;
    },
    setCurrentGroupedTransportRequestId(currentGroupedTransportRequestId: string | null) {
      this.currentGroupedTransportRequestId = currentGroupedTransportRequestId;
    },
    setCurrentItineraryId(itineraryId: number | null) {
      this.currentItineraryId = itineraryId;
      this.setCurrentMarker(null);
    },
    setItineraryToDelete(itineraryPointToDelete: number | null, label: string | null) {
      this.itineraryPointIdToDelete = itineraryPointToDelete;
      this.itineraryPointLabelToDelete = label;
    },
    setItineraryToEdit(itineraryIdToEdit: number | null) {
      this.itineraryIdToEdit = itineraryIdToEdit;
    },
    async deletePoint(itineraryPointId: number) {
      const itineraryStore = useItineraryStore();
      this.softRefresh = true;
      await itineraryStore.deleteItineraryPoint(itineraryPointId);
      await refreshNuxtData('itinerary-day');

      this.softRefresh = false;
    },
    async insertPoint(idCurrentItinerary: number, idTransportRequest: number, checkIfCanInsert: boolean, idConfiguration?: number) {
      this.softRefresh = true;
      const itineraryStore = useItineraryStore();

      if (checkIfCanInsert) {
        const result = await itineraryStore.canInsertTransportRequest(idCurrentItinerary, idTransportRequest);

        if (result.status == CanInsertTransportRequestEnum.KO) {
          this.softRefresh = false;
          throw CanNotInsertTransportRequestError();
        }

        if (result.status == CanInsertTransportRequestEnum.CHOOSE_ANOTHER_CONFIGURATION) {
          this.softRefresh = false;
          throw ChooseNewConfigurationError(result);
        }
      }

      await itineraryStore.insertTransportRequest(idCurrentItinerary, idTransportRequest, idConfiguration);
      await refreshNuxtData('itinerary-day');
      this.softRefresh = false;
    },
    reset() {
      this.$reset();
    },
    async createItinerary(itineraryPayload: ItineraryPayload) {
      const itineraryStore = useItineraryStore();

      this.softRefresh = true;
      await itineraryStore.createItinerary(itineraryPayload);
      await refreshNuxtData('itinerary-day');
      this.softRefresh = false;
    },
    async refreshOnlyCurrentItinerary() {
      this.softRefresh = true;
      await refreshNuxtData('itinerary-day');
      this.softRefresh = false;
    }
  }
});
