import type CanInsertTransportRequestResource from '~/api/resource/CanInsertTransportRequestResource';

interface ChooseNewConfiguration extends Error {
  name: 'ChooseNewConfiguration';
  canInsertTransportRequestResource: CanInsertTransportRequestResource;
}

export function ChooseNewConfigurationError(canInsertTransportRequestResource: CanInsertTransportRequestResource) {
  const error = new Error() as ChooseNewConfiguration;
  error.name = 'ChooseNewConfiguration';
  error.canInsertTransportRequestResource = canInsertTransportRequestResource;

  return error;
}
